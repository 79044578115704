import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Notice from '../modules/notice';
import ResearchGate from '../modules/researchgate';
import RelatedSites from '../modules/relatedsites';
import Visit from '../modules/visit';
import Google from '../modules/google';

function Home({ content }) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // CSRF 토큰을 메타 태그에서 가져오기
        const csrfToken = document.querySelector('meta[name="X-CSRF-Token"]').getAttribute('content');

        // Axios 요청에 CSRF 토큰을 헤더로 추가
        axios.get('/api/board/health/latest/5', {
            headers: {
                'X-CSRF-Token': csrfToken
            }
        })
            .then(response => {
                if (response.data.status === 'success') {
                    setPosts(response.data.posts);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching latest posts:', error);
                setLoading(false); // stop loading and show no posts
            });
    }, []);

    return (
        <div className="container">
            <div className="row">
                {/* Page content */}
                <div className="col-lg-8">
                    {/* Loading spinner or message */}
                    {loading && (
                        <div className="card mb-4">
                            <div className="card-body">
                                <h3 className="card-title">Loading...</h3>
                            </div>
                        </div>
                    )}

                    {/* No posts available */}
                    {!loading && posts.length === 0 && (
                        <div className="card mb-4">
                            <img className="card-img-top" src="/img/nopost_850x350.png" alt="There is no post" />
                            <div className="card-body">
                                <h2 className="card-title">No posts available</h2>
                                <p className="card-text">There are no recent posts available at this moment.</p>
                            </div>
                        </div>
                    )}

                    {/* Featured blog post */}
                    {!loading && posts.length > 0 && (
                        <>
                            <div className="card mb-4">
                                <a href={`/health/${posts[0].id}`}><img className="card-img-top" src="https://dummyimage.com/850x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                <div className="card-body">
                                    <div className="small text-muted">{new Date(posts[0].created_at).toLocaleDateString()}</div>
                                    <h2 className="card-title">{posts[0].title}</h2>
                                    <p className="card-text">{posts[0].content}</p>
                                    <a className="btn btn-primary" href={`/health/${posts[0].id}`}>Read more →</a>
                                </div>
                            </div>

                            {/* Nested row for non-featured blog posts */}
                            <div className="row">
                                {posts.slice(1).map((post, index) => (
                                    <div className="col-lg-6" key={post.id}>
                                        <div className="card mb-4">
                                            <a href={`/health/${post.id}`}><img className="card-img-top" src="https://dummyimage.com/700x350/dee2e6/6c757d.jpg" alt="..." /></a>
                                            <div className="card-body">
                                                <div className="small text-muted">{new Date(post.created_at).toLocaleDateString()}</div>
                                                <h2 className="card-title h4">{post.title}</h2>
                                                <p className="card-text">{post.content}</p>
                                                <a className="btn btn-primary" href={`/health/${post.id}`}>Read more →</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                {/* Side widgets */}
                <div className="col-lg-4">
                    {/* Notice widget */}
                    <div className="card mb-3" id="widget-notice"><Notice /></div>
                    {/* ResearchGate widget */}
                    <div className="card mb-3" id="widget-researchgate"><ResearchGate /></div>
                    {/* Related sites widget */}
                    <div className="card mb-3" id="widget-relatedsites"><RelatedSites /></div>
                    {/* Counter widget */}
                    <div className="card mb-3" id="widget-counter"><Visit /></div>
                    {/* Google search widget */}
                    <div className="card mb-3" id="widget-google"><Google content={content} /></div>
                </div>
            </div>
        </div>
    );
}

export default Home;
